import { Typography } from '@mui/material';
import { LogoField, LogoIcon, Page } from '@truvhq/ui';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useUpdateLogo } from 'entities/user/lib/useUpdateLogo';
import { useGetWidgetSettings } from 'hooks/customization';
import { useShowFileSizeError } from 'shared/lib/validation/useShowFileSizeError';

import { CompleteFormIndividual } from './CompleteFormIndividual';
import backgroundImage from '../assets/background.png';
import backgroundImage2x from '../assets/background@2x.png';

export const CompleteIndividual = () => {
  const { updateLogo, isLogoUpdating } = useUpdateLogo();
  const { data: widgetSettings } = useGetWidgetSettings();

  const showSizeError = useShowFileSizeError();

  return (
    <Page disablePadding className="flex h-full !flex-row">
      <div className="flex-1">
        <PerfectScrollbar className="flex flex-col">
          <LogoIcon className="mx-8 mt-8 shrink-0" width={74} />
          <div className="m-8 flex flex-grow flex-col items-center justify-center">
            <div>
              <Typography className="pb-3 text-[34px] font-bold" variant="h1">
                Welcome to Truv
              </Typography>
              <Typography className="pb-10" color="textSecondary">
                Fill in the below information to finish setting up your Truv Dashboard.
              </Typography>

              <div className="w-[536px]">
                <LogoField
                  caption="This logo will be displayed in the emails and verification screens sent to borrowers."
                  isLoading={isLogoUpdating}
                  value={widgetSettings?.logo ?? null}
                  onChange={updateLogo}
                  onSizeError={showSizeError}
                />

                <CompleteFormIndividual />
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      <img
        alt="background image"
        className="h-full object-cover"
        src={backgroundImage}
        srcSet={`${backgroundImage2x} 2x`}
      />
    </Page>
  );
};
