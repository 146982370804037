import { useMutation } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

import type { ProfileSettingsParams } from './common';

export const useUpdateProfile = () => {
  const { isPending, mutate } = useMutation({
    mutationFn: ({ firstName, lastName, phone }: ProfileSettingsParams) =>
      apiClient.updateProfile({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
      }),
  });

  return {
    isUpdating: isPending,
    updateProfile: mutate,
  };
};
