import { Button, TextField } from '@mui/material';
import { PhoneNumberField, Spinner } from '@truvhq/ui';
import { Controller, useForm } from 'react-hook-form';

import type { ProfileError } from 'entities/user/dto';
import { useGetProfile } from 'entities/user/lib/useGetProfile';
import { useUpdateProfile } from 'entities/user/lib/useUpdateProfile';
import { requiredFormFieldStyles } from 'shared/lib/format/requiredFormFieldStyles';
import { unpackPhone } from 'shared/lib/format/unpackPhone';
import { useMaskedPhone } from 'shared/lib/format/useMaskedPhone';
import { getPhoneError } from 'shared/lib/validation/getPhoneError';
import { isValidPhone } from 'shared/lib/validation/isValidPhone';

const isProfileError = (error: unknown): error is ProfileError => {
  return typeof error === 'object' && error !== null && 'code' in error && typeof error.code === 'string';
};

export const CompleteFormIndividual = () => {
  const { data: profile } = useGetProfile();
  const { updateProfile, isUpdating } = useUpdateProfile();

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<{
    firstName: string;
    lastName: string;
    phone: string;
  }>({
    defaultValues: {
      firstName: profile?.first_name || '',
      lastName: profile?.last_name || '',
      phone: profile?.phone_number || '',
    },
  });
  const phone = getValues('phone');
  const { maskedPhone, onPhoneChange } = useMaskedPhone({ phone });

  const onSubmit = () => {
    updateProfile(
      {
        firstName: getValues('firstName').trim(),
        lastName: getValues('lastName').trim(),
        phone: '+1' + phone.trim(),
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
        onError: (e) => {
          if (isProfileError(e) && e.phone_number) {
            setError('phone', { type: 'custom', message: 'Invalid phone number' });
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            required
            className="mt-4"
            error={errors.firstName != null}
            helperText={errors.firstName?.type === 'required' && 'First name is required'}
            label="Your first name"
            sx={requiredFormFieldStyles}
            variant="outlined"
          />
        )}
        rules={{
          required: true,
        }}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            required
            className="mt-4"
            error={errors.lastName != null}
            helperText={errors.lastName?.type === 'required' && 'First name is required'}
            label="Your last name"
            sx={requiredFormFieldStyles}
            variant="outlined"
          />
        )}
        rules={{
          required: true,
        }}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field: { onChange } }) => {
          return (
            <PhoneNumberField
              required
              className="mt-4"
              error={!!errors.phone}
              helperText={getPhoneError(errors.phone)}
              label="Your phone number"
              placeholder="(999) 888-7777"
              sx={requiredFormFieldStyles}
              unpackPhone={unpackPhone}
              value={maskedPhone}
              variant="outlined"
              onChange={(event) => {
                onPhoneChange(event);
                onChange(event.target.value);
              }}
            />
          );
        }}
        rules={{
          validate: {
            isCorrect: (value) => {
              if (!value || value === '') {
                return false;
              }

              return isValidPhone(value);
            },
          },
        }}
      />

      <Button
        fullWidth
        className="mt-8"
        disabled={isUpdating || !isValid}
        startIcon={isUpdating && <Spinner size={20} />}
        type="submit"
        variant="contained"
      >
        Start exploring
      </Button>
    </form>
  );
};
