import { LoadingScreen } from '@truvhq/ui';

import { useGetProfile } from 'entities/user/lib/useGetProfile';
import { useAuth } from 'shared/lib/auth/useAuth';

import { CompleteForm } from './CompleteForm';
import type { CompleteFormData } from '../lib/common';
import { useCompleteProfileInfo } from '../lib/useCompleteProfileInfo';

type ProfileProps = {
  showCompanyField: boolean;
};

export const CompleteFormWrapper = ({ showCompanyField }: ProfileProps) => {
  const { updateAuth } = useAuth();

  const { data: profileSettings, isPending: isLoading } = useGetProfile();

  const { complete, isPending, isSuccess } = useCompleteProfileInfo({
    updateCompany: showCompanyField,
    companyId: profileSettings?.company?.id,
    onSuccess: updateAuth,
  });

  if (isLoading || !profileSettings || isPending || isSuccess) {
    return <LoadingScreen />;
  }

  const onSubmit = (values: CompleteFormData) => {
    const submittedData = { ...values, email: profileSettings.email };

    complete(submittedData);
  };

  return (
    <>
      <CompleteForm
        isLoading={isLoading}
        showCompanyField={showCompanyField}
        submitting={isPending}
        onSubmit={onSubmit}
      />
    </>
  );
};
