import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { List, ListSubheader } from '@mui/material';
import { LogoIcon, NavButton } from '@truvhq/ui';
import { clsx } from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { getCustomSettingsTitle } from 'components/CustomSettings/utils';
import { SandboxSwitch } from 'components/SandboxSwitch/SandboxSwitch';
import { isNotApproved } from 'components/TeamMembers/tools';
import { useGetProfile } from 'entities/user/lib/useGetProfile';
import { useImpersonation } from 'hooks/impersonation';
import { useKeys } from 'hooks/keys';
import { useMembers } from 'hooks/team';
import { useNavigateToBilling } from 'hooks/useNavigateToBilling';
import { useAuth } from 'shared/lib/auth/useAuth';
import { useFiltersContext } from 'shared/lib/filters/useFiltersContext';
import { useSandboxContext } from 'shared/lib/sandbox/useSandboxContext';
import { urls } from 'shared/urls';
import type { Permission } from 'types/permissions';

import { InfoExternal } from './InfoExternal';
import { ACCORDION_TRANSITION_DURATION, NavItems } from './NavItems';
import { StyledBadge } from './StyledBadge';
import type { Item, State } from './types';
import { useSubmenuState } from './useSubmenuState';
import { isItemHidden } from './utils/isItemHidden';
import { isRoutePart } from './utils/isRoutePart';

type Section = {
  items: SubList[];
  key: string;
};

type SubList = {
  items: Item[];
  title: string;
  permission?: Permission;
};

const initialState = {
  [urls.home.url()]: false,
  [urls.account.url()]: false,
  [urls.settings.url()]: false,
  currentItem: undefined,
};

export const NavBar: FC = () => {
  const location = useLocation();
  const { logout, permissions, user } = useAuth();
  const { data: profileSettings } = useGetProfile();
  const history = useHistory();
  const { resetFilters } = useFiltersContext();
  const { keys } = useKeys();
  const { stopImpersonation } = useImpersonation();
  const handleBillingClick = useNavigateToBilling();
  const { submenuState, setSubmenuState } = useSubmenuState(initialState as State);
  const sandboxWrapperRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isSandboxOn, isSandboxVisible } = useSandboxContext();

  useEffect(() => {
    const expandedAccordion = Object.keys(submenuState).find(
      (key) =>
        submenuState[key] && (submenuState.currentItem === undefined || isRoutePart(submenuState.currentItem, key)),
    );

    let timeout: NodeJS.Timeout | null = null;

    if (expandedAccordion) {
      const targetAccordion = document.getElementById(`${expandedAccordion}-header`)?.parentElement;

      const scrollToTarget = () => {
        if (
          targetAccordion &&
          targetAccordion.getBoundingClientRect().bottom >
            Number(sandboxWrapperRef.current?.getBoundingClientRect().top)
        ) {
          targetAccordion.scrollIntoView();
        }
      };

      // wrap into timeout on accordion expand to wait for the accordion content width
      if (expandedAccordion === submenuState.currentItem) {
        timeout = setTimeout(scrollToTarget, ACCORDION_TRANSITION_DURATION);
      } else {
        scrollToTarget();
      }
    } else {
      const collapsedAccordion = Object.keys(submenuState).find(
        (key) => !submenuState[key] && submenuState.currentItem === key,
      );
      const targetAccordion = document.getElementById(`${collapsedAccordion}-header`);

      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy(
          0,
          -(
            Number(targetAccordion?.parentElement?.getBoundingClientRect().height) -
            Number(targetAccordion?.getBoundingClientRect().height)
          ),
        );
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [submenuState]);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      resetFilters();
      stopImpersonation();
      await logout();
      history.push('/');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [history, logout, resetFilters, stopImpersonation]);

  const handleItemClick = (currentItem?: string) => {
    setSubmenuState({ ...submenuState, currentItem } as State);
  };

  const enableMembersRequest = permissions.has('can_invite_users') && permissions.has('can_view_company_settings');

  const { data: members } = useMembers(enableMembersRequest);

  const notApprovedMembers = useMemo(() => members?.filter(isNotApproved).length, [members]);

  const role = profileSettings?.role.id;
  const isDeveloper = role === 'developer';
  const isOrdersManager = role === 'orders_manager';
  const isStaff = !!profileSettings?.staff_dashboard_url;
  const isIndividualVerifier = user?.isCompanyIndividual;
  const newKeys = isDeveloper && keys?.hasNewProdOrDevKeys ? 1 : 0;

  // Initial opening of submenu based on current route
  useEffect(() => {
    const key = Object.keys(submenuState).find((href) => isRoutePart(location.pathname, href));
    const isFromNavigate = submenuState.currentItem !== undefined;

    if (key) {
      setSubmenuState({
        ...submenuState,
        [key]: true,
        currentItem: isFromNavigate ? location.pathname : key,
      } as State);
    }
  }, []);

  const sections: Section[] = useMemo(
    () => [
      {
        key: 'main',
        items: [
          {
            title: '',
            items: [
              {
                title: urls.home.title,
                href: urls.home.url(),
                permission: urls.home.permission,
                hide: isOrdersManager && isSandboxOn,
                submenu: isSandboxOn
                  ? undefined
                  : [
                      {
                        title: urls.reporting.title,
                        href: urls.reporting.url(),
                        permission: urls.reporting.permission,
                      },
                      {
                        title: urls.usage.title,
                        href: urls.usage.url(),
                        permission: urls.usage.permission,
                        hide: isIndividualVerifier,
                      },
                      {
                        title: urls.performance.title,
                        href: urls.performance.url(),
                        permission: urls.performance.permission,
                      },
                      {
                        title: urls.managers.title,
                        href: urls.managers.url(),
                        permission: urls.managers.permission,
                        hide: isIndividualVerifier,
                      },
                    ],
              },
              {
                title: urls.emulator.title,
                href: urls.emulator.url(),
                permission: urls.emulator.permission,
              },
            ],
          },
          {
            title: urls.activity.title,
            items: [
              {
                title: urls.users.title,
                href: urls.users.url(),
                permission: urls.users.permission,
              },
              {
                title: urls.orders.title,
                href: urls.orders.url(),
                permission: urls.orders.permission,
              },
              {
                title: urls.tasks.title,
                href: urls.tasks.url(),
                permission: urls.tasks.permission,
              },
              {
                title: urls.coverage.title,
                href: urls.coverage.url(),
                permission: urls.coverage.permission,
                hide: isDeveloper,
              },
              // TODO: show to individual clients
              // {
              //   title: 'Pay as you go',
              //   href: '#',
              //   hide: !isIndividualVerifier,
              //   info: (
              //     <StyledBadge
              //       badgeContent="UPGRADE"
              //       slotProps={{
              //         badge: {
              //           className: 'pointer-events-none bg-primary-main text-white text-[10px]',
              //         },
              //       }}
              //     />
              //   ),
              // },
            ],
          },
          {
            title: urls.customization.title,
            permission: urls.customization.permission,
            items: [
              {
                title: urls.account.title,
                href: urls.account.url(),
                permission: urls.account.permission,
                submenu: [
                  {
                    title: urls.branding.title,
                    href: urls.branding.url(),
                    permission: urls.branding.permission,
                  },
                  {
                    title: getCustomSettingsTitle(permissions),
                    href: urls.customSettings.url(),
                    permission: urls.customSettings.permission,
                  },
                ],
              },
              {
                title: urls.templates.title,
                href: urls.templates.url(),
                permission: urls.templates.permission,
              },
              {
                title: urls.fieldsCustomization.title,
                href: urls.fieldsCustomization.url(),
                permission: urls.fieldsCustomization.permission,
                hide: isIndividualVerifier,
              },
              {
                title: urls.marketplace.title,
                href: urls.marketplace.url(),
                permission: urls.marketplace.permission,
                hide: !isStaff,
              },
            ],
          },
          {
            title: urls.development.title,
            permission: urls.development.permission,
            items: [
              {
                title: urls.keys.title,
                href: urls.keys.url(),
                permission: urls.keys.permission,
                info: newKeys ? <StyledBadge badgeContent={newKeys} color="secondary" /> : undefined,
              },
              {
                title: urls.platforms.title,
                href: urls.platforms.url(),
                permission: urls.platforms.permission,
              },
              {
                title: urls.logs.title,
                href: urls.logs.url(),
                permission: urls.logs.permission,
              },
              {
                title: urls.webhooks.title,
                href: urls.webhooks.url(),
                permission: urls.webhooks.permission,
              },
              // {
              //   title: urls.systemStatus.title,
              //   href: urls.systemStatus.url(),
              //   permission: urls.systemStatus.permission,
              // },
            ],
          },
          {
            title: 'Help',
            permission: urls.supportTickets.permission,
            items: [
              {
                title: urls.supportTickets.title,
                href: urls.supportTickets.url(),
                permission: urls.supportTickets.permission,
              },
              {
                title: urls.docs.title,
                customAction() {
                  window.open(urls.docs.parameterUrl?.(isOrdersManager), '_blank');
                },
                permission: urls.docs.permission,
                info: <InfoExternal className="text-white" />,
                hide: isIndividualVerifier,
              },
            ],
          },
        ],
      },
      {
        key: 'bottom',
        items: [
          {
            title: '',
            items: [
              {
                title: urls.settings.title,
                href: urls.settings.url(),
                submenu: [
                  {
                    title: urls.company.title,
                    href: urls.company.url(),
                    permission: urls.company.permission,
                  },
                  {
                    title: urls.team.title,
                    href: urls.team.parameterUrl?.() ?? '/',
                    info: notApprovedMembers ? (
                      <StyledBadge badgeContent={notApprovedMembers} color="secondary" />
                    ) : undefined,
                    permission: urls.team.permission,
                  },
                  {
                    title: urls.billing.title,
                    permission: urls.billing.permission,
                    customAction: handleBillingClick,
                    info: <InfoExternal className="text-white" />,
                  },
                  {
                    title: 'Profile',
                    href: urls.profile.url(),
                  },
                  {
                    title: 'Log out',
                    customAction: handleLogout,
                  },
                ],
              },
            ],
          },
          // TODO: show to individual clients
          // {
          //   title: 'Refer a friend',
          //   href: '#',
          //   hide: !isIndividualVerifier,
          // },
        ],
      },
    ],
    [
      handleBillingClick,
      handleLogout,
      isDeveloper,
      isIndividualVerifier,
      isOrdersManager,
      isSandboxOn,
      isStaff,
      newKeys,
      notApprovedMembers,
      permissions,
    ],
  );

  return (
    <>
      <PerfectScrollbar
        className="flex max-h-screen w-216 flex-grow flex-col bg-grey90"
        // @ts-expect-error
        containerRef={(ref) => ref && (scrollContainerRef.current = ref)}
      >
        <div className="flex justify-start py-8 pl-6">
          <RouterLink to="/">
            <LogoIcon className="h-5 w-auto text-white" />
          </RouterLink>
        </div>
        <div className="relative flex flex-grow flex-col bg-grey90 pt-2">
          {sections.map((section, sectionIndex) => (
            <List
              key={section.key}
              className={clsx({
                'mt-[-12px]': sectionIndex < 1 && section.items[0].items[0].hide,
                'border-t-[1px] border-t-grey40/10': sectionIndex > 0,
              })}
            >
              {section.items.map((item, index) =>
                isItemHidden(item, permissions) ? null : (
                  <List
                    key={item.title}
                    disablePadding
                    className={clsx('flex flex-col gap-0.5', index !== section.items.length - 1 && 'mb-3')}
                    subheader={
                      <ListSubheader disableGutters className="static z-0 mx-6 bg-transparent leading-9">
                        {item.title}
                      </ListSubheader>
                    }
                  >
                    <NavItems
                      items={item.items}
                      pathname={location.pathname}
                      permissions={permissions}
                      setSubmenuState={setSubmenuState}
                      submenuState={submenuState}
                      onItemClick={handleItemClick}
                    />
                  </List>
                ),
              )}
            </List>
          ))}
        </div>
        {isSandboxVisible && (
          <div ref={sandboxWrapperRef} className="sticky bottom-0 flex flex-col bg-white/10 py-1.5 backdrop-blur-lg">
            {isStaff && (
              <NavButton
                title="Staff dashboard"
                onClick={() => {
                  window.open(profileSettings.staff_dashboard_url, '_blank');
                }}
              />
            )}

            <div className="pl-4">
              <SandboxSwitch invert />
            </div>
          </div>
        )}
      </PerfectScrollbar>
    </>
  );
};
